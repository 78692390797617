import * as React from "react"
import { withPreviewResolver } from "gatsby-source-prismic"

import SEO from "../components/SEO"
import linkResolver from "../utils/linkResolver"

const PreviewPage = ({ isPreview, isLoading, location }) => {
  const previewText = isPreview ? "Loading" : "Not a preview!"
  return (
    <>
      <SEO
        image=""
        location={location}
        title="Preview page"
        shortDesc="Preview page"
        description="Preview page"
        noFollow={true}
        noIndex={true}
      />
      <div>
        <p>{previewText}</p>
      </div>
    </>
  )
}

export default withPreviewResolver(PreviewPage, {
  repositoryName: "alpacked",
  linkResolver,
})
