const linkResolver = ({ node, key, value }) => doc => {
  if (doc.type === "technologies_page") {
    return `/technologies`
  }
  if (doc.type === "consulting_page") {
    return `/consulting`
  }
  if (doc.type === "home") {
    return `/`
  }
  if (doc.type === "single_technologies_page") {
    return `/technologies/${doc.uid}`
  }
  if (doc.type === "services_page") {
    return `/services/${doc.uid}`
  }

  if (doc.type === "consulting") {
    return `/consulting/${doc.uid}`
  }

  if (doc.type === "services") {
    return `/services`
  }

  if (doc.type === "blog_post") {
    return `/blog/${doc.uid}`
  }

  return `/blog/${doc.uid}`
}

module.exports = linkResolver
